import Vue from 'vue'
import FeaturedSlider from './FeaturedSlider.vue'

Vue.config.productionTip = false
Vue.prototype.FeaturedSlider = window.featuredSliderInfo.featured_slider

if(document.getElementById('wisdom-featured-slider')) {
  let sliderClass = document.getElementById('wisdom-featured-slider').classList[0]
  Vue.prototype.layout = sliderClass

  new Vue({
    render: h => h(FeaturedSlider),
  }).$mount('#wisdom-featured-slider')
}
