<template>
  <div class="featured-slider">
    <div class="featured-slider__wrapper">
      <template v-for="(slider, i) in featuredSliders">
        <div v-show="i == currentIndex" :key="i" class="featured-slider__container" :class="{
          'col': layout == 'col',
          'row': layout == 'row',
          'active': i == currentIndex,
          }">
          <img v-if="slider.image" class="featured-slider__image" :srcset="slider.image" :alt="slider.title">
          <div class="featured-slider__content">
            <h2 v-if="slider.title">{{ slider.title }}</h2>
            <p v-if="slider.excerpt">{{ slider.excerpt }}</p>
            <a :href="slider.link">Tell me more <i aria-hidden="true" class="fas fa-arrow-right"></i></a>
          </div>
        </div>
      </template>
    </div>

    <a v-if="currentIndex !== getSliderLength" class="featured-slider__arrow next" @click="currentIndex++" v-html="nextArrow"></a>
    <a v-if="currentIndex !== 0" class="featured-slider__arrow prev" @click="currentIndex--" v-html="prevArrow"></a>
  </div>
</template>

<script>

export default {
  data() {
    return {
      featuredSliders: [
        {
          image: '',
          title: '',
          excerpt: '',
          link: ''
        }
      ],
      nextArrow: '<i aria-hidden="true" class="fas fa-chevron-right"></i>',
      prevArrow: '<i aria-hidden="true" class="fas fa-chevron-left"></i>',
      currentIndex: 0,
    }
  },
  mounted() {
    this.featuredSliders = this.FeaturedSlider
  },
  computed: {
    getSliderLength() {
      return this.featuredSliders.length -1
    }
  }
}
</script>

<style lang="scss" scoped>
.featured-slider {
  position: relative;
  padding: 8rem 4rem;

  &__wrapper {
    max-width: 1440px;
    margin: auto;
    position: relative;
    display: flex;
  }

  &__container {
    display: flex;
    align-items: center;
    height: 100%;
    transition: all 0.3s ease;
    opacity: 0;
    visibility: hidden;
    width: 0px;

    @media all and (max-width: 960px) {
      flex-flow: column;
    }

    &.col {
      flex-flow: column;

      .featured-slider__image,
      .featured-slider__content {
        width: 100%;
        padding: 0;
      }
    }

    &.active {
      width: 100%;
      opacity: 1;
      visibility: visible;
      transition: all 0.3s ease;
    }
  }

  &__arrow {
    cursor: pointer;
    position: absolute;
    top: 50%;
    font-size: 1.5rem;

    &.next {
      right: 20px;
    }

    &.prev {
      left: 20px;
    }
  }

  &__image {
    width: 60%;
    display: block;

    @media all and (max-width: 960px) {
      width: 100%;
    }
  }

  &__content {
    width: calc(40% - 3rem);
    padding: 3rem;

    @media all and (max-width: 960px) {
      width: 100%;
      padding: 0;
    }

    h2 {
      font-family: "Amsi Pro", Sans-serif;
      font-weight: 700;
      font-size: 2.5rem;
      margin-top: 2rem;

      @media all and (max-width: 960px) {
        font-size: 1.8rem;
      }
    }

    p {
      color: #63666A;
      font-family: "Amsi Pro", Sans-serif;
      font-size: 1.2rem;
      letter-spacing: 1px;
      font-weight: 400;
      line-height: 1.5em;
      overflow-wrap: break-word;
    }

    a {
      color: #00B2A9;
      letter-spacing: 2px;
      text-transform: uppercase;
      text-decoration: none;
    }
  }
}
</style>
